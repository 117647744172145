import { HttpStatusCode, isAxiosError } from 'axios'
import { UserRejectedRequestError } from 'viem'
import { ZodError } from 'zod'

import { type MessageDescriptor } from '@x10/lib-core/i18n'
import { invariant } from '@x10/lib-core/utils'

import { ApiErrorCode, AppErrorCode } from '@src/domain/core/errors/base'
import { dynamicId } from '@src/domain/i18n/utils/dynamic-id'

const parseApiError = (error: unknown) => {
  invariant(isAxiosError(error), 'error must be `AxiosError` instance')

  const response = error.response

  if (
    response?.status === HttpStatusCode.Forbidden &&
    response?.config.url === '/api/v1/info/markets'
  ) {
    return {
      code: ApiErrorCode.NotAvailableInYourRegion,
      details: 'Not available in your region',
    }
  }

  if (
    response?.status === HttpStatusCode.Forbidden &&
    response?.config.url === '/api/v1/user/withdrawal' &&
    response?.config.method === 'POST'
  ) {
    return {
      code: ApiErrorCode.AccountBlocked,
      details: 'Account is blocked due to risk analytics',
    }
  }

  const responseDetails = JSON.stringify({
    method: response?.config.method,
    url: response?.config.url,
    response: response?.data,
  })

  let errorCode = response?.data?.error?.code ?? ApiErrorCode.ApplicationError

  if (response?.data === 'INVALID_REFERRAL_CODE') {
    errorCode = ApiErrorCode.InvalidReferralCode
  }

  return {
    code: errorCode,
    details: `API request failed: ${responseDetails}`,
  }
}

const UNKNOWN_APP_ERROR = {
  type: 'app',
  code: AppErrorCode.Unknown,
  details: 'Unknown error',
  message: {
    id: dynamicId(`error.app.code.${AppErrorCode.Unknown}`),
  },
} as const

type Result =
  | { type: 'api'; code: ApiErrorCode; details?: string; message?: MessageDescriptor }
  | { type: 'app'; code: AppErrorCode; details?: string; message?: MessageDescriptor }

// FIXME: Return custom `Error` instance
export const parseError = (errorToParse: unknown): Result => {
  const error = errorToParse instanceof Error ? errorToParse : null

  if (!error) {
    return UNKNOWN_APP_ERROR
  }

  const errorType = isAxiosError(error) ? 'api' : 'app'

  if (errorType === 'api') {
    const apiError = parseApiError(error)
    const isErrorCodeSupported = Object.values(ApiErrorCode).includes(apiError.code)

    return {
      type: errorType,
      code: apiError.code,
      details: apiError.details,
      message: {
        id: dynamicId(
          `error.api.code.${isErrorCodeSupported ? apiError.code : ApiErrorCode.ApplicationError}`,
        ),
      },
    }
  }

  if (
    error instanceof UserRejectedRequestError ||
    error.cause instanceof UserRejectedRequestError
  ) {
    return {
      type: errorType,
      code: AppErrorCode.UserRejectedRequest,
    }
  }

  if (errorType === 'app' && error?.name === 'SyntaxError') {
    return {
      type: errorType,
      code: AppErrorCode.CantParseServerResponse,
      details: `Can't parse server response: ${error.message}`,
      message: {
        id: dynamicId(`error.app.code.${AppErrorCode.CantParseServerResponse}`),
      },
    }
  }

  if (errorType === 'app' && error instanceof ZodError) {
    return {
      type: errorType,
      code: AppErrorCode.UnexpectedResponseFormat,
      details: `Unexpected response format: ${error.message}`,
      message: {
        id: dynamicId(`error.app.code.${AppErrorCode.UnexpectedResponseFormat}`),
      },
    }
  }

  return UNKNOWN_APP_ERROR
}
