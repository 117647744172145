import { UserRejectedRequestError } from 'viem'

// BE API does not have unified error codes,
// so we need to define an extension here
const EXT_API_CODE_BASE = 1000000

export enum ApiErrorCode {
  // Leverage update
  LeverageExceedsMaxLeverage = 1050,
  LeverageMaxPositionValueExceeded = 10501,
  LeverageInsufficientMargin = 1052,

  // Orders
  OrderQtyLessThanMinTradeSize = 1120,
  InvalidQtyWrongSizeIncrement = 1121,
  OrderValueExceedsMaxOrderValue = 1122,
  InvalidQtyPrecision = 1123,
  InvalidPriceWrongPriceMovement = 1124,
  InvalidPricePrecision = 1125,
  MaxOpenOrdersNumberExceeded = 1126,
  MaxPositionValueExceeded = 1127,
  InvalidPositionTpslQty = 1129,
  MissingOrderPrice = 1130,
  ReduceOnlyOrderSizeExceedsPositionSize = 1136,
  ReduceOnlyOrderPositionIsMissing = 1137,
  ReduceOnlyOrderPositionSameSide = 1138,
  OrderCostExceedsBalance = 1140,
  InvalidPriceAmount = 1141,

  // Withdrawal
  WithdrawalDisabled = 1609,
  WithdrawalTransferFeeIsTooLow = 1610,

  // Ext
  ApplicationError = EXT_API_CODE_BASE,
  NotAvailableInYourRegion,
  AccountBlocked,
  InvalidReferralCode,
}

export enum AppErrorCode {
  Unknown = 1000,

  // API response
  CantParseServerResponse = 2000,
  UnexpectedResponseFormat,

  // Wallet
  CantGetStarkPrivateKey = 3000,
  WalletIsNotEligible,
  UserRejectedRequest,
}

/**
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error
 */
export class X10AppError extends Error {
  errorCode: AppErrorCode

  constructor(errorCode: AppErrorCode, message?: string, options?: ErrorOptions) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(message, options)

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, X10AppError)
    }

    this.name = 'X10AppError'
    this.errorCode = errorCode
  }

  static is(error: unknown, code: AppErrorCode): error is X10AppError {
    return error instanceof X10AppError && error.errorCode === code
  }
}

/**
 * @deprecated Use `parseError` instead
 */
export const isUserRejectedRequestError = (error: unknown): error is Error => {
  return (
    error instanceof UserRejectedRequestError ||
    (error instanceof Error && error.cause instanceof UserRejectedRequestError)
  )
}
