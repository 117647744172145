'use client'

import { useEffect } from 'react'
import NextError from 'next/error'

import { captureExceptionWithSentry } from '@src/domain/core/errors/capture-exception-with-sentry'

/**
 * See https://docs.sentry.io/platforms/javascript/guides/nextjs/manual-setup/#react-render-errors-in-app-router
 */
export default function GlobalError({ error }: { error: Error & { digest?: string } }) {
  useEffect(() => {
    captureExceptionWithSentry(error)
  }, [error])

  return (
    <html lang="en">
      <body>
        {/* @ts-expect-error This is the default Next.js error component, but it doesn't allow omitting the statusCode property yet. */}
        <NextError statusCode={undefined as unknown} />
      </body>
    </html>
  )
}
