import * as Sentry from '@sentry/nextjs'

import { parseError } from './parse-error'

export const captureExceptionWithSentry = (
  error: unknown,
  extra: Record<string, unknown> = {},
) => {
  const parsedError = parseError(error)

  Sentry.captureException(error, {
    extra: {
      ...extra,
      errorType: parsedError.type,
      errorCode: parsedError.code,
      errorDetails: parsedError.details,
    },
  })
}
